import React from 'react';
import { alpha, styled } from '@mui/material';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { Conference } from '../types/Conference';
import { useNavigate } from 'react-router-dom';

const ConferenceTable = ({ conferences }: { conferences: Conference[] }) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1 },
  ];
  const ARCHIVED_OPACITY = 0.2;
  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.current`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ARCHIVED_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ARCHIVED_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ARCHIVED_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ARCHIVED_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));

  return (
    <StripedDataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'date', sort: 'desc' }],
        },
      }}
      disableRowSelectionOnClick
      rows={conferences}
      columns={columns}
      getRowId={(r) => r.confId}
      getRowClassName={(params) => (params.row.archived ? 'current' : 'archived')}
      onRowClick={(params) => navigate(`/conference/${params.row.confId}`)}
    />
  );
};

export default ConferenceTable;
