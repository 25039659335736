import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { AttendeeService } from '../../services/AttendeeService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Attendee } from '../../types/Attendee';
import { Conference } from '../../types/Conference';
import { ConferenceService } from '../../services/ConferenceService';
import Button from '@mui/material/Button';
import Confetti from 'react-confetti';
import { Alert } from '@mui/material';

const shuffle = (array: Attendee[]): Attendee[] => {
  // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex--);

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

let attendeeIndex = 0;

const ConferencePickWinnerPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [conference, setConference] = useState<Conference | null | undefined>();
  const [attendees, setAttendees] = useState<Attendee[] | null | undefined>(undefined);
  const [eligibleAttendees, setEligibleAttendees] = useState<Attendee[] | null | undefined>(
    undefined,
  );
  const [displayedAttendee, setDisplayedAttendee] = useState<Attendee | undefined>();
  const [displayConfetti, setDisplayConfetti] = useState(false);
  const [intervalId, setIntervalId] = useState<any | undefined>(undefined);
  const [saveError, setSaveError] = useState(false);

  useEffect(() => {
    (async () => {
      let [a, c] = await Promise.all([
        AttendeeService.getAllByConfId(id),
        ConferenceService.getPublic(id),
      ]);
      if (a && c) {
        setEligibleAttendees(shuffle(a.filter((b: Attendee) => b.eligible && !b.prizeWon)));
        a = shuffle(a.filter((attendee: Attendee) => !attendee.prizeWon));
        setConference(c);
        setAttendees(a);

        if (Array.isArray(a) && intervalId === undefined) {
          setIntervalId(
            setInterval(() => {
              setDisplayedAttendee(a[attendeeIndex++]);
              if (attendeeIndex >= a.length) {
                attendeeIndex = 0;
              }
            }, 100),
          );
        }
      } else {
        setConference(null);
        setAttendees(null);
      }
    })();
    return () => {
      clearInterval(intervalId);
      setIntervalId(undefined);
    };
  }, [id]);

  const handleChooseWinner = async () => {
    if (eligibleAttendees) {
      clearInterval(intervalId);
      const drawnAttendee = eligibleAttendees[Math.floor(Math.random() * eligibleAttendees.length)];
      drawnAttendee.prizeWon = true;
      setSaveError(!(await AttendeeService.claimPrize(drawnAttendee)));
      setDisplayedAttendee(drawnAttendee);
      setDisplayConfetti(true);
    }
  };

  if (attendees === undefined || conference === undefined) return <Typography>Loading</Typography>;
  if (attendees === null || conference === null)
    return <Typography>No Attendees or Conference Found</Typography>;

  return (
    <Box>
      {displayConfetti && <Confetti wind={0.04} colors={['#141d3e', '#ff3131']} />}
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon color={'secondary'} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">Picking a winner for {conference.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Button color="secondary" onClick={handleChooseWinner} variant="contained">
            Draw Name
          </Button>
        </Grid>

        {conference.prizeImgUrl && conference.prizeImgUrl !== '' ? (
          <Grid item xs={12} textAlign="center" mt={1}>
            {conference.prizeImgUrl && (
              <img height={150} src={conference.prizeImgUrl} alt={conference.prizeName} />
            )}
          </Grid>
        ) : (
          <Grid item xs={12} textAlign="center">
            <Typography variant="body1">{conference.prizeName}</Typography>
          </Grid>
        )}
        <Grid item xs={12} textAlign="center">
          <Typography variant="h4">&nbsp;</Typography>
        </Grid>

        <Grid item xs={12} textAlign="center">
          {displayedAttendee && (
            <Typography variant="h2" sx={{ fontFamily: 'Cabin Sketch' }}>
              {displayedAttendee.firstName} {displayedAttendee.lastName}
            </Typography>
          )}
        </Grid>
      </Grid>
      {saveError && (
        <Alert severity="error" variant="standard" className="alert">
          Failed to save!
        </Alert>
      )}
    </Box>
  );
};

export default ConferencePickWinnerPage;
