import React, { useEffect, useState } from 'react';
import { ConferenceService } from '../../services/ConferenceService';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Conference } from '../../types/Conference';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ConferenceTable from '../../components/ConferenceTable';

const ConferencesPage = () => {
  const [conferences, setConferences] = useState<Conference[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const allConferences = (await ConferenceService.getAll()) || [];
      const archivedConfs = allConferences.filter((c: Conference) => c.archived);
      const newConfs = allConferences.filter((c: Conference) => !c.archived);
      setConferences([...newConfs, ...archivedConfs]);
    })();
  }, []);
  return (
    <Grid container spacing={2} direction="row">
      <Grid container spacing={2} direction="row" mt={1}>
        <Grid item xs={11}>
          <Typography variant="h4" mb={1}>
            Conferences
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" color="secondary" onClick={() => navigate('/conference/new')}>
            Create
          </Button>
        </Grid>
      </Grid>
      <ConferenceTable conferences={conferences} />
    </Grid>
  );
};

export default ConferencesPage;
